<template>
    <div class="init">
        <avue-form :option="option" ref="form_"
             v-model="fromValue">
        </avue-form>
    </div>
</template>
<script setup>
    import { ref,defineExpose,defineEmits,getCurrentInstance,computed } from 'vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import copyRules from './copyRules'
    const { $t } = getCurrentInstance().proxy;

    let baseUrl = sessionStorage.getItem('baseURL') || 'http://api.waastest.cloud:20801' 
    const optionPro = computed(() => {
        return {
            submitBtn: false,
            emptyBtn: false,
            column: [{
                label: $t('currencySetNft.name1'),
                prop: 'tenantId',
                type: 'select',
                dicUrl: `${baseUrl}/admin/tenant/page?size=10000`,
                cascader: ['appId'],
                dicFormatter: (e)=> e.records,
                props:{
                    value:'id',
                    label:'name'
                },
                disabled: parms_.value.createFrom !== 2?true: false,
                rules: [{
                    required: true,
                    message: "请选择",
                    trigger: "blur"
                }]
            },{
                label: $t('currencySetNft.name2'),
                prop: 'appId',
                type: 'select',
                dicUrl: `${baseUrl}/admin/userappinfo/page?tenantId={{tenantId}}`,
                dicFormatter: (e)=> e.records,
                props:{
                    value:'appId',
                    label:'appName'
                },
                // disabled: parms_.value.createFrom !== 2?true: false,
                rules: [{
                    required: true,
                    message: "请选择",
                    trigger: "blur"
                }],
                change:(column)=>{
                    if(column.value){
                        getNftWriteListData(column.value)
                    }
                }
            }],
            tabsActive: 2,
            group: [
                {
                    label: $t('otherWallatNft.name3'),
                    prop: 'group1',
                    column: [{
                        label: $t('news.text1'),
                        prop: 'zhContent1',
                        type: 'textarea',
                        minRows: 2,
                        rules: [{
                            required: true,
                            message: "请选择",
                            trigger: "blur"
                        }],
                        maxRows: 4
                    },{
                        label: $t('news.text2'),
                        prop: 'hkContent1',
                        type: 'textarea',
                        minRows: 2,
                        rules: [{
                            required: true,
                            message: "请选择",
                            trigger: "blur"
                        }],
                        maxRows: 4
                    },{
                        label: 'English',
                        prop: 'enContent1',
                        type: 'textarea',
                        minRows: 2,
                        rules: [{
                            required: true,
                            message: "请选择",
                            trigger: "blur"
                        }],
                        maxRows: 4
                    }]
                }, {
                    label: $t('otherWallatNft.name4'),
                    prop: 'group2',
                    column: [{
                        label: $t('news.text1'),
                        prop: 'zhContent2',
                        type: 'textarea',
                        minRows: 2,
                        rules: [{
                            required: true,
                            message: "请选择",
                            trigger: "blur"
                        }],
                        maxRows: 4
                    },{
                        label: $t('news.text2'),
                        prop: 'hkContent2',
                        type: 'textarea',
                        minRows: 2,
                        rules: [{
                            required: true,
                            message: "请选择",
                            trigger: "blur"
                        }],
                        maxRows: 4
                    },{
                        label: 'English',
                        prop: 'enContent2',
                        type: 'textarea',
                        minRows: 2,
                        rules: [{
                            required: true,
                            message: "请选择",
                            trigger: "blur"
                        }],
                        maxRows: 4
                    }]
                }
            ]}
    });
    
    const option = ref(optionPro)
    const fromValue = ref()

    let post_ = {
        zhContent1: {descriptionType:1,language:'zh',descriptionId:''},
        hkContent1: {descriptionType:1,language:'hk',descriptionId:''},
        enContent1: {descriptionType:1,language:'en',descriptionId:''},
        zhContent2: {descriptionType:2,language:'zh',descriptionId:''},
        hkContent2: {descriptionType:2,language:'hk',descriptionId:''},
        enContent2: {descriptionType:2,language:'en',descriptionId:''},
    }
    const collectionId = ref(undefined)
    const parms_ = ref({})
    const emit = defineEmits(['successFun'])
    const form_ = ref(null)
    const rules = ref(null);rules.value = copyRules.mainRules
    const completeFun = ()=>{
        form_.value.validate((e)=>{
            if(e){
                for(let i in post_){
                    let obj_ = Object.assign({}, {collectionId:collectionId.value}, post_[i])
                    obj_.content = fromValue.value[i];
                    obj_.tenantId = fromValue.value['tenantId'];
                    obj_.appId = fromValue.value['appId'];
                    if(post_[i].descriptionId){
                        Api_.updateNftWrite(obj_)
                    }else{
                        Api_.addNftWrite(obj_)
                    }
                }
            }
        })
        emit('successFun')
        ElNotification({
            message: $t('alert.succBind'),
            type: 'success',
        })
    }
    const getNftWriteListData = (appId)=>{
        Api_.getNftWriteList({
            collectionId: collectionId.value,
            tenantId: fromValue.value.tenantId,
            appId: appId
        }).then(res=>{
            const fromValue_ = {}
            if(res.code === 0){
                if(res.data.records.length > 0){
                    for(let item of res.data.records){
                        let str_= item.language + 'Content' + item.descriptionType;
                        post_[str_].descriptionId = item.descriptionId
                        fromValue_[str_] = item.content
                    }
                }else{
                    for(const key in post_){
                        post_[key].descriptionId = '';
                    }
                }
            }
            fromValue.value = fromValue_
        })
    }
    const setData1 = (scope)=>{//获得列表数据 
        parms_.value = scope;
        form_.value.resetForm();
        collectionId.value = scope.collectionId;
        if(scope.createFrom !== 2){
            optionPro.value.column[0].value = scope.tenantId
            fromValue.value['tenantId'] = scope.tenantId
        }
    }
    defineExpose({setData1,completeFun})
</script>